import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useModalContext } from '../lib/modal'
import Button, { ButtonGroup } from './Button'
import EmailForm from './EmailForm'

function BoldEmailAddress( emailAddress ) {
  return <strong>{ emailAddress }</strong>
}

export default function LoginForm() {
  const { openModal, closeModal } = useModalContext()
  const [waiting, setWaiting] = useState( false )

  const onEmailSuccess = emailAddress => {
    openModal( {
      content: (
        <>
          <p>
            <FormattedMessage
              description="Login: Email success"
              defaultMessage="We’ve sent an email to <strong>{ email }</strong>. Please check your inbox, and click the button in the email to log in."
              values={ { 
                strong: BoldEmailAddress,
                email: emailAddress,
              } }
              id="jd0pT9"
            />
          </p>
          <ButtonGroup $centered>
            <Button $textColor="grey9">
              <button
                type="button"
                onClick={ closeModal }
              >
                <FormattedMessage
                  description="Okay (aknowledging a notification)"
                  defaultMessage="Okay"
                  id="CxxZZV"
                />
              </button>
            </Button>
          </ButtonGroup>
        </>
      ),
    } )
  }

  const doLogin = emailAddress => {
    setWaiting( true )

    fetch( '/api/auth/email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify( {
        email: emailAddress,
      } ),
    } )
      .then( r => r.json() )
      .then( r => onEmailSuccess( r.email ) )
      .finally( () => setWaiting( false ) )
  }

  return (
    <EmailForm 
      isWaiting={ waiting }
      onSubmit={ doLogin }
    />
  )
}
