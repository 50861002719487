import { FormattedMessage, useIntl } from 'react-intl'
import Background from '../components/layout/Background'
import Container, { ShiftAlign } from '../components/layout/Container'
import Stack from '../components/layout/Stack'
import AppChrome from '../components/AppChrome'
import Hero from '../components/Hero'
import LoginForm from '../components/LoginForm'
import MetaTags from '../components/MetaTags'

export { getServerSideProps } from '../graphql/globals-only'

function LoginPage() {
  const { formatMessage } = useIntl()

  return (
    <AppChrome letsDiscussButton={ false }>
      <MetaTags
        url="/login"
        title={ formatMessage( {
          description: 'Log In',
          defaultMessage: 'Log In',
          id: 'iFNaoo',
        } ) }
      />
      <Background color="grey8">
        <Hero 
          title={ (
            <FormattedMessage
              description="Log In"
              defaultMessage="Log In"
              id="iFNaoo"
            />
          ) }
          bottomPad="small"
        >
          <FormattedMessage
            description="Log In Instruction"
            defaultMessage="Enter your email address here to log in."
            id="mwlhCb"
          />
        </Hero>
        <Container>
          <ShiftAlign $colShiftEnd={ 2 }>
            <Stack $bottom={ 4 }>
              <LoginForm />
            </Stack>
          </ShiftAlign>
        </Container>
      </Background>
    </AppChrome>
  )
}

export default LoginPage
