import { useId } from 'react'
import styled from 'styled-components'

function TextInput( {
  name, 
  value, 
  type = 'text', 
  label,
  placeholder = null,
  onChange = null,
  readOnly = false,
  multiline = false, 
  rows = 12,
} ) {
  const id = `field_id_${ name }_${ useId() }`

  return (
    <StyledTextInput>
      <label htmlFor={ id }>
        <span>
          { label }
        </span>
        { multiline && (
          <textarea 
            name={ name }
            id={ id }
            value={ value === null ? '' : value }
            placeholder={ placeholder }
            onChange={ onChange }
            readOnly={ readOnly }
            rows={ rows }
          />
        ) }
        { !multiline && (
          <input 
            type={ type }
            name={ name }
            id={ id }
            value={ value === null ? '' : value }
            placeholder={ placeholder }
            onChange={ onChange }
            readOnly={ readOnly }
          />
        ) }
      </label>
    </StyledTextInput>
  )
}

const StyledTextInput = styled.div`
  font-size: 1rem;
  text-align: left;

  label span {
    display: block;
    margin-bottom: 0.5rem;
    ${ p => p.theme.typo.neueHaasBold }
    font-size: ${ p => p.theme.typo.sizes.normal };
  }

  input,
  textarea {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 0.6rem 1rem;
    font-size: inherit;
    background: transparent;
    border: 1px solid ${ p => p.theme.colors.grey7 };
    border-radius: 3px;
    transition: border-color .3s ease-out;

    &:focus {
      outline: none;
      border-color: ${ p => p.theme.colors.highlight };
    }
  }
`

export default TextInput
