import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import TextInput from './TextInput'
import Button from './Button'
import Loader from './Loader'

export default function EmailForm( { onSubmit, isWaiting } ) {
  const [email, setEmail] = useState( '' )
  const [loaderWorking, setLoaderWorking] = useState( isWaiting )

  const submit = e => {
    e.preventDefault()
    onSubmit( email )
  }

  useEffect( () => {
    if ( isWaiting ) {
      setLoaderWorking( true )
    }
  }, [isWaiting] )

  return (
    <StyledEmailForm onSubmit={ submit }>
      <TextInput
        name="email"
        label="Email"
        type="email"
        value={ email }
        onChange={ e => setEmail( e.target.value ) }
      />
      <Button
        $color="grey2"
        $textColor="grey9"
      >
        <button 
          type="submit"
          disabled={ isWaiting || loaderWorking }
        >
          <FormattedMessage
            description="Send email"
            defaultMessage="Send email"
            id="3csh9F"
          />
          <Loader
            isLoading={ isWaiting }
            inline
            width="28px"
            onComplete={ () => setLoaderWorking( false ) }
          />
        </button>
      </Button>
    </StyledEmailForm>
  )
}

const StyledEmailForm = styled.form`
  display: flex;
  gap: 1rem;
  align-items: flex-end;
  font-size: 1rem;

  > *:first-child {
    flex: 1 0 auto;
  }
`
